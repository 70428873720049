<script>
import { Role } from '../helpers/role.js'
import { Business } from '../helpers/business'
export default {
  name: 'nav',
  computed: { 
    sidebarItems1 () {
      return [{
        _name: 'CSidebarNav',
        _children: [{
            _name: 'CSidebarNavItem',
            name: this.$t('menu.dashboard'),
            to: '/dashboard',
            icon: 'cil-speedometer',
            badge: {
              color: 'primary',
              text: 'NEW'
            }
          },
          {
            _name: 'CSidebarNavTitle',
            _children: ['Theme']
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Colors',
            to: '/theme/colors',
            icon: 'cil-drop'
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Typography',
            to: '/theme/typography',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavTitle',
            _children: ['Components']
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Base',
            route: '/base',
            icon: 'cil-puzzle',
            items: [{
                name: 'Breadcrumbs',
                to: '/base/breadcrumbs'
              },
              {
                name: 'Cards',
                to: '/base/cards'
              },
              {
                name: 'Carousels',
                to: '/base/carousels'
              },
              {
                name: 'Collapses',
                to: '/base/collapses'
              },
              {
                name: 'Jumbotrons',
                to: '/base/jumbotrons'
              },
              {
                name: 'List Groups',
                to: '/base/list-groups'
              },
              {
                name: 'Navs',
                to: '/base/navs'
              },
              {
                name: 'Navbars',
                to: '/base/navbars'
              },
              {
                name: 'Paginations',
                to: '/base/paginations'
              },
              {
                name: 'Popovers',
                to: '/base/popovers'
              },
              {
                name: 'Progress Bars',
                to: '/base/progress-bars'
              },
              {
                name: 'Switches',
                to: '/base/switches'
              },
              {
                name: 'Tabs',
                to: '/base/tabs'
              },
              {
                name: 'Tooltips',
                to: '/base/tooltips'
              }
            ]
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Buttons',
            route: '/buttons',
            icon: 'cil-cursor',
            items: [{
                name: 'Buttons',
                to: '/buttons/standard-buttons'
              },
              {
                name: 'Button Dropdowns',
                to: '/buttons/dropdowns'
              },
              {
                name: 'Button Groups',
                to: '/buttons/button-groups'
              },
              {
                name: 'Brand Buttons',
                to: '/buttons/brand-buttons'
              },
              {
                name: 'Loading Buttons',
                to: '/buttons/loading-buttons',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              }
            ]
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Charts',
            to: '/charts',
            icon: 'cil-chart-pie'
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Editors',
            route: '/editors',
            icon: 'cil-code',
            items: [{
                name: 'Code editors',
                to: '/editors/code-editors',
                icon: 'cil-code',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              },
              {
                name: 'Text editors',
                to: '/editors/text-editors',
                icon: 'cil-justify-left'
              }
            ]
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Forms',
            route: '/forms',
            icon: 'cil-notes',
            items: [{
                name: 'Basic forms',
                to: '/forms/basic-forms'
              },
              {
                name: 'Advanced forms',
                to: '/forms/advanced-forms',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              },
              {
                name: 'Validation forms',
                to: '/forms/validation-forms',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              },
              {
                name: 'Multiselect',
                to: '/forms/multi-select',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              }
            ]
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Google Maps',
            to: '/google-maps',
            icon: 'cil-map',
            badge: {
              color: 'danger',
              text: 'PRO'
            }
          },
          // {
          //   _name: 'CSidebarNavDropdown',
          //   name: 'Icons',
          //   route: '/icons',
          //   icon: 'cil-star',
          //   items: [{
          //       name: 'CoreUI Icons',
          //       to: '/icons/coreui-icons',
          //       badge: {
          //         color: 'info',
          //         text: 'NEW'
          //       }
          //     },
          //     {
          //       name: 'Brands',
          //       to: '/icons/brands'
          //     },
          //     {
          //       name: 'Flags',
          //       to: '/icons/flags'
          //     }
          //   ]
          // },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Notifications',
            route: '/notifications',
            icon: 'cil-bell',
            items: [{
                name: 'Alerts',
                to: '/notifications/alerts',
              },
              {
                name: 'Badges',
                to: '/notifications/badges'
              },
              {
                name: 'Modals',
                to: '/notifications/modals'
              },
              {
                name: 'Toaster',
                to: '/notifications/toaster',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              }
            ]
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Plugins',
            route: '/plugins',
            icon: 'cil-input-power',
            items: [{
                name: 'Draggable',
                to: '/plugins/draggable',
                icon: 'cil-cursor-move',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              },
              {
                name: 'Calendar',
                to: '/plugins/calendar',
                icon: 'cil-calendar',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              },
              {
                name: 'Spinners',
                to: '/plugins/spinners',
                icon: 'cil-circle',
                badge: {
                  color: 'danger',
                  text: 'PRO'
                }
              }
            ]
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Tables',
            route: '/tables',
            icon: 'cil-list',
            items: [{
                name: 'Basic Tables',
                to: '/tables/tables',
                icon: 'cil-list',
              },
              {
                name: 'Advanced tables',
                to: '/tables/advanced-tables',
                icon: 'cil-list-rich'
              }
            ]
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Widgets',
            to: '/widgets',
            icon: 'cil-calculator',
            badge: {
              color: 'primary',
              text: 'NEW',
              shape: 'pill'
            }
          },
          {
            _name: 'CSidebarNavDivider'
          },
          {
            _name: 'CSidebarNavTitle',
            _children: ['Extras']
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Pages',
            route: '/pages',
            icon: 'cil-star',
            items: [{
                name: 'Login',
                to: '/pages/login'
              },
              {
                name: 'Register',
                to: '/pages/register'
              },
              {
                name: 'Error 404',
                to: '/pages/404'
              },
              {
                name: 'Error 500',
                to: '/pages/500'
              }
            ]
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Apps',
            route: '/apps',
            icon: 'cil-layers',
            _children: [{
                _name: 'CSidebarNavDropdown',
                name: 'Invoicing',
                route: '/apps/invoicing',
                icon: 'cil-spreadsheet',
                items: [{
                  name: 'Invoice',
                  to: '/apps/invoicing/invoice',
                  badge: {
                    color: 'danger',
                    text: 'PRO'
                  }
                }]
              },
              {
                _name: 'CSidebarNavDropdown',
                name: 'Email',
                route: '/apps/email',
                icon: 'cil-envelope-closed',
                items: [{
                    name: 'Inbox',
                    to: '/apps/email/inbox',
                    icon: 'cil-envelope-closed',
                    badge: {
                      color: 'danger',
                      text: 'PRO'
                    }
                  },
                  {
                    name: 'Message',
                    to: '/apps/email/message',
                    icon: 'cil-envelope-open',
                    badge: {
                      color: 'danger',
                      text: 'PRO'
                    }
                  },
                  {
                    name: 'Compose',
                    to: '/apps/email/compose',
                    icon: 'cil-envelope-letter',
                    badge: {
                      color: 'danger',
                      text: 'PRO'
                    }
                  }
                ]
              }
            ]
          },
          {
            _name: 'CSidebarNavDivider',
            _class: 'm-2'
          },
          {
            _name: 'CSidebarNavTitle',
            _children: ['Labels']
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Label danger',
            icon: {
              name: 'cil-star',
              class: 'text-danger'
            },
            label: true
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Label info',
            icon: {
              name: 'cil-star',
              class: 'text-info'
            },
            label: true
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Label warning',
            icon: {
              name: 'cil-star',
              class: 'text-warning'
            },
            label: true
          }
        ]
      }]
    },
    sidebarItems ()
    {
      let vm=this
      let user= vm.$store.state.st_authentication.user
      debugger
      if(user==null) return
      if(user.role==Role.Super_Admin){
        return  [{
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: this.$t('menu.dashboard'),
              to: '/system',
              icon: 'cil-speedometer',
              badge: {
                color: 'primary',
                text: 'NEW'
              }
            },
            {
              _name: 'CSidebarNavTitle',
              _children: ['Admin']
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('menu.user_list'),
              to: '/system/ls-user',
              icon: 'cil-pencil'
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('menu.client_list'),
              to: '/system/ls-client',
              icon: 'cil-pencil'
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('menu.supply_list'),
              to: '/system/ls-supply',
              icon: 'cil-pencil'
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('menu.company_list'),
              to: '/system/ls-cong-ty',
              icon: 'cil-list-rich'
            },
            {
              _name: 'CSidebarNavItem',
              name: this.$t('menu.unit_list'),
              to: '/system/ls-unit',
              icon: 'cil-list-rich'
            },
            {
              _name: 'CSidebarNavDropdown',
              name: this.$t('menu.report_list'),
              route: '/system',
              icon: 'cil-puzzle',
              items:[
              {
                name: 'Xem báo cáo',
                to: '/system/rpt-income-per-day'
              }]
            }]
          }
        ]
      }
      else {
        switch (user.company.business) {
          case Business.cafe://Quản lý kinh doanh cafe
            return this.cafe_menu(user)

          case Business.water://Quản lý kinh doanh water
            return this.water_menu(user)

          case Business.bida://Quản lý kinh doanh bida
            return this.bida_menu(user)

          case Business.vlxd://Buôn bán vật liệu xây dựng
            return this.vlxd_menu(user)

          case Business.dentist://Quản lý phòng khám răng
            return this.dentist_menu(user)
            
          case Business.pharmacy://Quản lý nhà thuốc
            return this.water_menu(user)
            
          case Business.karaoke://Quản lý kinh doanh Karaoke
            return this.karaoke_menu(user)
            
          case Business.food://Quản lý kinh doanh quán ăn
            return this.food_menu(user)
            
          case Business.pawn://Quản lý kinh doanh cầm đồ
            return this.pawn_menu(user)
            
          case Business.football://Quản lý sân bóng đá mini
            return this.football_menu(user)
            
          case Business.tole://Quản lý kinh doanh Tole
            return this.tole_menu(user)
            
          case Business.hotel://Quản lý kinh doanh hotel
            return this.hotel_menu()
            
          case Business.hostel://Quản lý kinh doanh hostel
            return this.hostel_menu(user)
            
          case Business.spa://Quản lý phòng SPA
            return this.spa_menu(user)
            
          case Business.massage://Quản lý Cơ sở massage
            return this.massage_menu(user)
            
          case Business.oto://Quản lý trung tâm sửa chữa ô tô
            return this.oto_menu(user)
            
          case Business.gym://Quản lý phòng tập gym
            
          default:
            return []
        }
      }
    },

    name_sp(){
      let user= this.$store.state.st_authentication.user
      if(user==null) return '/login'
      if(user.company.business==Business.dentist){
        return this.$t('menu.product_list')
      }else{
        return this.$t('menu.product_list')
      }
    },
    order_sp(){
      let user= this.$store.state.st_authentication.user
      if(user==null) return '/login'
      if(user.company.business==Business.dentist){
        return this.$t('menu.client_list')
      }else if(user.company.business==Business.pawn){
        return this.$t('menu.pawn_list')
      }else{
        return this.$t('menu.order_list')
      }
    },
    business_link(){
      let user= this.$store.state.st_authentication.user
      if(user==null) return '/login'
      if(user.company.business==Business.cafe){
        return '/cafe'
      }else if(user&&user.company.business==Business.vlxd){
        return '/vlxd'
      }else if(user&&user.company.business==Business.bida){
        return '/bida'
      }else if(user&&user.company.business==Business.water){
        return '/water'
      }else if(user&&user.company.business==Business.dentist){
        return '/dentist'
      }else if(user&&user.company.business==Business.pharmacy){
        return '/pharmacy'
      }else if(user&&user.company.business==Business.karaoke){
        return '/karaoke'
      }else if(user&&user.company.business==Business.food){
        return '/food'
      }else if(user&&user.company.business==Business.pawn){
        return '/pawn'
      }else if(user&&user.company.business==Business.football){
        return '/football'
      }else if(user&&user.company.business==Business.tole){
        return '/tole'
      }else if(user&&user.company.business==Business.hotel){
        return '/hotel'
      }else if(user&&user.company.business==Business.hostel){
        return '/hostel'
      }else if(user&&user.company.business==Business.spa){
        return '/spa'
      }else if(user&&user.company.business==Business.massage){
        return '/massage'
      }else if(user&&user.company.business==Business.gym){
        return '/gym'
      }else if(user&&user.company.business==Business.oto){
        return '/oto'
      }else{
        return '/system'
      }
    },
    is_admin(){
      let user= this.$store.state.st_authentication.user
      return user&&user.role==Role.Admin
    },
    is_super(){
      let user= this.$store.state.st_authentication.user
      return user&&user.role==Role.Super_Admin
    },
    is_branch(){
      let user= this.$store.state.st_authentication.user
      return user&&user.role==Role.Branch_Admin
    },
    is_sub(){
      let user= this.$store.state.st_authentication.user
      return user&&user.role==Role.Sub_Admin
    },
    is_user(){
      let user= this.$store.state.st_authentication.user
      return user&&user.role==Role.User
    },
    business(){
      let user= this.$store.state.st_authentication.user
      if (user==null) return null
      return user.company.business
    },
    user_login () {
      return this.$store.state.st_authentication.user
    },
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  },
  methods: {
    cafe_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/cafe',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Cafe system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/cafe/ls-user',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/cafe/ls-supply',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/cafe/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.table_list'),
            to: '/cafe/ls-table',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/cafe/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/cafe/ls-im-product'
              }
            ]
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/cafe/ls-unit',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/cafe/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/cafe/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/cafe/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/cafe/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/cafe/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/cafe/rpt-supply-debt'
              }
            ]
          }
        ]
        // if(user.company.cocon){
        //   temp.splice(1,0,
        //     {
        //       key: "ds-branch",
        //       _name: this.$t('menu.branch_list'),
        //       to: '/cafe/ls-branch',
        //       icon: 'cil-list-rich'
        //     })
        // }else{
        //   _.remove(temp, {
        //       name: "cafe-branch"
        //   })
        // }
        temp=header_children.concat(temp)
        debugger
        return header_children
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/cafe/ls-user',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/cafe/ls-supply',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/cafe/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.table_list'),
            to: '/cafe/ls-table',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/cafe/ls-product'
              },
              {
                _name: this.$t('menu.product_import'),
                to: '/cafe/ls-im-product'
              }
            ]
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/cafe/ls-unit',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/cafe/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/cafe/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/cafe/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/cafe/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/cafe/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/cafe/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/cafe/ls-order',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/cafe/ls-unit',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/cafe/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.table_list'),
            to: '/cafe/ls-table',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    water_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/water',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Water system']
      }]
      if(user.role==Role.Admin){
        let temp=[{
          _name: 'CSidebarNavItem',
          name: this.$t('menu.user_list'),
          to: '/water/ls-user',
          icon: 'cil-pencil'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.client_list'),
          to: '/water/ls-client',
          icon: 'cil-pencil'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.staff_list'),
          to: '/water/ls-staff',
          icon: 'cil-pencil'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.supply_list'),
          to: '/water/ls-supply',
          icon: 'cil-pencil'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.order_list'),
          to: '/water/ls-order',
          icon: 'cui-cart'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.delivery_list'),
          to: '/water/ls-delivery',
          icon: 'cil-list-rich'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.return_list'),
          to: '/water/ls-return',
          icon: 'cil-list-rich'
        },
        {
          _name: this.$t('menu.product_management'),
          route: '/product/',
          icon: 'cil-list-rich',
          
          _children:[
            {
              name: this.$t('menu.product_list'),
              to: '/water/ls-product'
            },
            {
              name: this.$t('menu.product_import'),
              to: '/water/ls-im-product'
            }
          ]
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.unit_list'),
          to: '/water/ls-unit',
          icon: 'cil-list-rich'
        },
        {
          _name: this.$t('menu.report_list'),
          route: '/baocao/',
          icon: 'cil-list-rich',
          
          _children:[
            {
              name: this.$t('menu.user_working'),
              to: '/water/rpt-user-working'
            },
            {
              name: this.$t('menu.report_income'),
              to: '/water/rpt-income-per-day'
            },
            {
              name: this.$t('menu.report_activity'),
              to: '/water/rpt-company-activity'
            },
            {
              name: this.$t('menu.report_import_export'),
              to: '/water/rpt-im-ex-re'
            },
            {
              name: this.$t('menu.report_client_debt'),
              to: '/water/rpt-client-debt',
              icon: 'cui-calendar'
            },
            {
              name: this.$t('menu.report_supply_debt'),
              to: '/water/rpt-supply-debt'
            }
          ]
        }]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key: "ds-branch",
              _name: this.$t('menu.client_list'),
              to: '/water/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "water-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[{
          _name: 'CSidebarNavItem',
          name: this.$t('menu.user_list'),
          to: '/water/ls-user',
          icon: 'cil-pencil'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.client_list'),
          to: '/water/ls-client',
          icon: 'cil-pencil'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.staff_list'),
          to: '/water/ls-staff',
          icon: 'cil-pencil'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.supply_list'),
          to: '/water/ls-supply',
          icon: 'cil-pencil'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.order_list'),
          to: '/water/ls-order',
          icon: 'cui-cart'
        },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.delivery_list'),
            to: '/water/ls-delivery',
            icon: 'cil-list-rich'
          },
        {
          _name: this.$t('menu.product_management'),
          route: '/product/',
          icon: 'cil-list-rich',
          
          _children:[
            {
              name: this.$t('menu.product_list'),
              to: '/water/ls-product'
            },
            {
              name: this.$t('menu.report_import_export'),
              to: '/water/ls-im-product'
            }
          ]
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.unit_list'),
          to: '/water/ls-unit',
          icon: 'cil-list-rich'
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('menu.other_outcome_list'),
          to: '/water/ls-other-outcome',
          icon: 'cil-list-rich'
        },
        {
          _name: this.$t('menu.report_list'),
          route: '/baocao/',
          icon: 'cil-list-rich',
          
          _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/water/rpt-user-working'
              },
            {
              name: this.$t('menu.report_income'),
              to: '/water/bao-cao',
              icon: 'cui-calendar'
            },
              {
                name: this.$t('menu.report_activity'),
                to: '/water/rpt-company-activity'
              },
            {
              name: this.$t('menu.report_import_export'),
              to: '/water/rpt-im-ex-re'
            },
              {
                name: this.$t('menu.report_client_debt'),
                to: '/water/rpt-client-debt'
              },
              {
                _name: this.$t('menu.report_supply_debt'),
                to: '/water/rpt-supply-debt'
              }
          ]
        }]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/water/ls-order',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.delivery_list'),
            to: '/water/ls-delivery',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/water/ls-unit',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/water/ls-other-outcome',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    bida_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/bida',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Bida system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/bida/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/bida/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/bida/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: this.$t('menu.table_management'),
            route: '/table/',
            icon: 'cil-list-rich',
            
            _children:[
            {
              name: this.$t('menu.table_list'),
              to: '/bida/ls-table'
            },
            {
              name: this.$t('menu.table_type_list'),
              to: '/bida/ls-type'
            }]
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/bida/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/bida/ls-im-product'
              }
            ]
          },
          {
          name: 'CSidebarNavItem',
            _name: this.$t('menu.unit_list'),
            to: '/bida/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          name: 'CSidebarNavItem',
            _name: this.$t('menu.other_outcome_list'),
            to: '/bida/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/bida/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/bida/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/bida/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/bida/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/bida/rpt-supply-debt'
              }]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key: "ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/bida/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "bida-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/bida/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/bida/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/bida/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: this.$t('menu.table_management'),
            icon: 'cil-list-rich',
            
            _children:[
            {
              name: this.$t('menu.table_list'),
              to: '/bida/ls-table'
            },
            {
              name: this.$t('menu.table_type_list'),
              to: '/bida/ls-type'
            }]
          },
          {
            _name: this.$t('menu.product_management'),
            icon: 'cil-list-rich',
            
            _children:[
                  {
                    name: this.$t('menu.productt_list'),
                    to: '/bida/ls-product'
                  },
                  {
                    name: this.$t('menu.product_import'),
                    to: '/bida/ls-im-product'
                  }
              ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/bida/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/bida/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/bida/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/bida/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/bida/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/bida/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/bida/rpt-supply-debt'
              }]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/bida/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/bida/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/bida/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.table_list'),
            to: '/bida/ls-table',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    vlxd_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/vlxd',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Vlxd system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/vlxd/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/vlxd/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.driver_list'),
            to: '/vlxd/ls-staff',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/vlxd/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.project_list'),
            to: '/vlxd/ls-project',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/vlxd/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/vlxd/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.car_list'),
            to: '/vlxd/ls-car',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/vlxd/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/vlxd/ls-im-product'
              }
            ]
          },
          
          {
            _name: this.$t('menu.order_management'),
            route: '/order/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.booking_list'),
                to: '/vlxd/ls-order'
              },
              {
                name: this.$t('menu.delivery_list'),
                to: '/vlxd/ls-delivery'
              },
              {
                name: this.$t('menu.return_list'),
                to: '/vlxd/ls-return'
              }
            ]
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/vlxd/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/vlxd/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/vlxd/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/vlxd/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_client_debt'),
                to: '/vlxd/rpt-client-debt'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/vlxd/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,{
            key:"ds-branch",
            _name: this.$t('menu.branch_list'),
            to: '/vlxd/ls-branch',
            icon: 'cil-list-rich'
          })
        }else{
          _.remove(temp, {
              name: "vlxd-branch"
          })
        }

        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/vlxd/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/vlxd/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.driver_list'),
            to: '/vlxd/ls-staff',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/vlxd/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/vlxd/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/vlxd/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.car_list'),
            to: '/vlxd/ls-car',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.project_list'),
            to: '/vlxd/ls-supply',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/vlxd/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/vlxd/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/vlxd/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.delivery_list'),
            to: '/vlxd/ls-delivery',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/vlxd/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/vlxd/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/vlxd/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/vlxd/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_client_debt'),
                to: '/vlxd/rpt-client-debt'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/vlxd/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.booking_list'),
            to: '/vlxd/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.delivery_list'),
            to: '/vlxd/ls-delivery',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.car_list'),
            to: '/vlxd/ls-car',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/vlxd/ls-client',
            icon: 'cil-pencil'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    dentist_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/dentist',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Dentist System']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.treatment_list'),
            to: '/dentist/ls-treatment',
            icon: 'cil-circle'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/dentist/ls-client',
            icon: 'cil-list'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/dentist/ls-supply',
            icon: 'cil-list'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/dentist/ls-user',
            icon: 'cil-user'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.doctor_list'),
            to: '/dentist/ls-doctor',
            icon: 'cil-list'
          },
          {
            _name: 'CSidebarNavDropdown',
            name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-star',
            items:[
              {
                name: this.$t('menu.product_list'),
                to: '/dentist/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/dentist/ls-im-product'
              },
              {
                name: this.$t('menu.unit_list'),
                to: '/dentist/ls-unit'
              },
              {
                name: this.$t('menu.other_outcome_list'),
                to: '/dentist/ls-other-outcome'
              }
            ]
          },
          {
            _name: 'CSidebarNavDropdown',
            name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-star',
            items:[
              {
                name: this.$t('menu.user_working'),
                to: '/dentist/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/dentist/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/dentist/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/dentist/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/dentist/rpt-supply-debt'
              }
            ]
          },
          {
            _name: 'CSidebarNavDropdown',
            name: 'Icons',
            route: '/icons',
            icon: 'cil-star',
            items: [{
                name: 'CoreUI Icons',
                to: '/icons/coreui-icons',
                badge: {
                  color: 'info',
                  text: 'NEW'
                }
              },
              {
                name: 'Brands',
                to: '/icons/brands'
              },
              {
                name: 'Flags',
                to: '/icons/flags'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: 'CSidebarNavItem',
              name: this.$t('menu.branch_list'),
              to: '/dentist/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
            name: "dentist-branch"
          })
        }
        temp=header_children.concat(temp)
        return [{
          _name: 'CSidebarNav',
          _children: temp
        }]
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.treatment_list'),
            to: '/dentist/ls-treatment',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/dentist/ls-client',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/dentist/ls-supply',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/dentist/ls-user',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.doctor_list'),
            to: '/dentist/ls-doctor',
            icon: 'cil-pencil'
          },
          {
            _name: 'CSidebarNavDropdown',
            name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-puzzle',
            items:[
              {
                name: this.$t('menu.product_list'),
                to: '/dentist/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/dentist/ls-im-product'
              }
            ]
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/dentist/ls-unit',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/dentist/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: 'CSidebarNavDropdown',
            name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-puzzle',
            items:[
              {
                name: this.$t('menu.user_working'),
                to: '/dentist/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/dentist/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/dentist/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/dentist/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/dentist/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return [{
          _name: 'CSidebarNav',
          _children: temp
        }]
      }else{
        let temp=[
          {
            _name: 'CSidebarNavItem',
            name: this.$t('menu.treatment_list'),
            to: '/dentist/ls-treatment',
            icon: 'cil-pencil'
          },

          {
            _name: this.$t('menu.client_list'),
            to: '/dentist/ls-client',
            icon: 'cil-pencil'
          },
          {
            _name: this.$t('menu.product_list'),
            to: '/dentist/ls-product',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.unit_list'),
            to: '/dentist/ls-unit',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.other_outcome_list'),
            to: '/dentist/ls-other-outcome',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return [{
          _name: 'CSidebarNav',
          _children: temp
        }]
      }
    },
    pharmacy_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/pharmacy',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Pharmacy system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/pharmacy/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/pharmacy/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/pharmacy/ls-supply',
            icon: 'cil-pencil'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/pharmacy/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/pharmacy/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/pharmacy/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/pharmacy/ls-unit',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/pharmacy/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/pharmacy/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/pharmacy/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/pharmacy/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/pharmacy/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/pharmacy/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key: "ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/pharmacy/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "pharmacy-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/pharmacy/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/pharmacy/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/pharmacy/ls-supply',
            icon: 'cil-pencil'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/pharmacy/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/pharmacy/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/pharmacy/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/pharmacy/ls-unit',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/pharmacy/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/pharmacy/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/pharmacy/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/pharmacy/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/pharmacy/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/pharmacy/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.product_list'),
            to: '/pharmacy/ls-product',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/pharmacy/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/pharmacy/ls-order',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    karaoke_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/karaoke',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Karaoke system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/karaoke/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/karaoke/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/karaoke/ls-order',
            icon: 'cui-cart'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/karaoke/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_type_list'),
            to: '/karaoke/ls-type',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/karaoke/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/karaoke/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/karaoke/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/karaoke/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/karaoke/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/karaoke/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/karaoke/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/karaoke/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/karaoke/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/karaoke/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "karaoke-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/karaoke/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/karaoke/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/karaoke/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/karaoke/ls-order',
            icon: 'cui-cart'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/karaoke/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_type_list'),
            to: '/karaoke/ls-type',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/karaoke/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/karaoke/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/karaoke/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/karaoke/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/karaoke/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/karaoke/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/karaoke/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/karaoke/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/karaoke/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/karaoke/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/karaoke/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/karaoke/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/karaoke/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/karaoke/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_type_list'),
            to: '/karaoke/ls-type',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    food_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/food',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Food system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/food/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/food/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/food/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/food/ls-order',
            icon: 'cui-cart'
          },

          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.table_list'),
            to: '/food/ls-table',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                _name: this.$t('menu.product_list'),
                to: '/food/ls-product'
              },
              {
                _name: this.$t('menu.product_import'),
                to: '/food/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/food/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/food/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/food/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/food/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/food/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/food/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/food/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/food/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "food-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/food/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/food/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/food/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/food/ls-order',
            icon: 'cui-cart'
          },

          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.table_list'),
            to: '/food/ls-table',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/food/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/food/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/food/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/food/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/food/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/food/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/food/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/food/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/food/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/food/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/food/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/food/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/food/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.table_list'),
            to: '/food/ls-table',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    pawn_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/pawn',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Pawn system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/pawn/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.pawn_list'),
            to: '/pawn/ls-order',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/pawn/ls-client',
            icon: 'cil-pencil'
          },
          {
            _name: this.$t('menu.supply_list'),
            to: '/pawn/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.product_list'),
            to: '/pawn/ls-product',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/pawn/ls-unit',
            icon: 'icon-list'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/food/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/pawn/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/pawn/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/pawn/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_client_refun'),
                to: '/pawn/rpt-client-refun'
              },
              {
                name: this.$t('menu.report_client_expire'),
                to: '/pawn/rpt-client-expire'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/pawn/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "pawn-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/pawn/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.pawn_list'),
            to: '/pawn/ls-order',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/pawn/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/pawn/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.product_list'),
            to: '/pawn/ls-product',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/pawn/ls-unit',
            icon: 'icon-list'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/pawn/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/pawn/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/pawn/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/pawn/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_client_refun'),
                to: '/pawn/rpt-client-refun'
              },
              {
                name: this.$t('menu.report_client_expire'),
                to: '/pawn/rpt-client-expire'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/pawn/ls-client',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/pawn/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.pawn_list'),
            to: '/pawn/ls-order',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.product_list'),
            to: '/pawn/ls-product',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/pawn/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/pawn/ls-unit',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    football_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/football',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Footbal system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/football/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/football/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/football/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.football_list'),
            to: '/football/ls-san',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.football_type_list'),
            to: '/football/ls-type',
            icon: 'icon-list'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/football/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/football/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/football/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/football/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/football/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/football/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/football/rpt-income-per-day'
              },
              {
                v: this.$t('menu.report_activity'),
                to: '/pawn/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/football/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/football/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/football/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "football-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/football/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/football/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/football/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.football_list'),
            to: '/football/ls-san',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.football_type_list'),
            to: '/football/ls-type',
            icon: 'icon-list'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/football/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/football/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/football/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/football/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/football/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/football/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/football/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/football/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/football/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/football/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/football/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/football/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/football/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/football/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.football_list'),
            to: '/football/ls-san',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.football_type_list'),
            to: '/football/ls-type',
            icon: 'icon-list'
          },
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    tole_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/tole',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Tole system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/tole/ls-order',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.delivery_list'),
            to: '/tole/ls-delivery',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.return_list'),
            to: '/tole/ls-return',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/tole/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/tole/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/tole/ls-user',
            icon: 'cil-pencil'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/tole/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/tole/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/tole/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/tole/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/tole/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/tole/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/tole/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/tole/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_client_debt'),
                to: '/tole/rpt-client-debt'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/tole/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/tole/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
            name: "tole-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/tole/ls-order',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.delivery_list'),
            to: '/tole/ls-delivery',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/tole/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/tole/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/tole/ls-user',
            icon: 'cil-pencil'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/tole/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/tole/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/tole/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/tole/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/tole/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/tole/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/tole/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/tole/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_client_debt'),
                to: '/tole/rpt-client-debt'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/tole/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/tole/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.delivery_list'),
            to: '/tole/ls-delivery',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.client_list'),
            to: '/tole/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.product_list'),
            to: '/tole/ls-product',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    hotel_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/hotel',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Hotel system']
      }]
          if(user.role==Role.Admin){
            let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/hotel/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/hotel/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/hotel/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/hotel/ls-order',
            icon: 'cui-cart'
          },
          {
            name: 'Quản lý phòng',
            route: '/object/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.room_type_list'),
                to: '/hotel/ls-type'
              },
              {
                name: this.$t('menu.room_list'),
                to: '/hotel/ls-room'
              }
            ]
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/hotel/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/hotel/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/hotel/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/hotel/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/hotel/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/hotel/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/hotel/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/hotel/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/hotel/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/hotel/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "hotel-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/hotel/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/hotel/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/hotel/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/hotel/ls-order',
            icon: 'cui-cart'
          },
          {
            name: 'Quản lý phòng',
            route: '/object/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.room_type_list'),
                to: '/hotel/ls-type'
              },
              {
                name: this.$t('menu.room_list'),
                to: '/hotel/ls-room'
              }
            ]
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/hotel/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/hotel/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/hotel/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/hotel/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/pawn/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/hotel/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/hotel/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/hotel/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/hotel/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/hotel/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/hotel/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/hotel/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/hotel/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/hotel/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_type_list'),
            to: '/hotel/ls-type',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    hostel_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/hostel',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Hostel system']
      }]
          if(user.role==Role.Admin){
            let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/hostel/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list_hostel'),
            to: '/hostel/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/hostel/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/hostel/ls-order',
            icon: 'cui-cart'
          },
          {
            name: 'Quản lý phòng',
            route: '/object/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.room_type_list'),
                to: '/hostel/ls-type',
              },
              {
                name: this.$t('menu.room_list'),
                to: '/hostel/ls-room'
              }
            ]
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/hostel/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/hostel/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/hostel/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/hostel/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/hostel/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/hostel/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/hostel/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/hostel/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/hostel/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/hostel/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "hostel-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/hostel/ls-user',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list_hostel'),
            to: '/hostel/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/hostel/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/hostel/ls-order',
            icon: 'cui-cart'
          },
          {
            name: 'Quản lý phòng',
            route: '/object/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.room_type_list'),
                to: '/hostel/ls-type'
              },
              {
                name: this.$t('menu.room_list'),
                to: '/hostel/ls-room'
              }
            ]
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.product_list'),
                to: '/hostel/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/hostel/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/hostel/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/hostel/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/hostel/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/hostel/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/hostel/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/hostel/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/hostel/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/hostel/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list_hostel'),
            to: '/hostel/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/hostel/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/hostel/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/hostel/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_type_list'),
            to: '/hostel/ls-type',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    spa_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/spa',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Spa system']
      }]
          if(user.role==Role.Admin){
            let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/spa/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/spa/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/spa/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/spa/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            _children:[
              {
                name: this.$t('menu.service_list'),
                to: '/spa/ls-service'
              },
              {
                name: this.$t('menu.product_list'),
                to: '/spa/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/spa/ls-im-product'
              }
            ]
          },
          {
            name: 'Quản lý phòng',
            route: '/object/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.room_type_list'),
                to: '/spa/ls-type'
              },
              {
                name: this.$t('menu.room_list'),
                to: '/spa/ls-room'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/spa/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/spa/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/spa/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/spa/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/spa/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/spa/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/spa/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/spa/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "spa-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/spa/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/spa/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/spa/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/spa/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.service_list'),
                to: '/spa/ls-service'
              },
              {
                name: this.$t('menu.product_list'),
                to: '/spa/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/spa/ls-im-product'
              }
            ]
          },
          {
            name: 'Quản lý phòng',
            route: '/object/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.room_type_list'),
                to: '/spa/ls-type'
              },
              {
                name: this.$t('menu.room_list'),
                to: '/spa/ls-room'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/spa/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/spa/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/spa/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/spa/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/spa/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/spa/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/spa/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/spa/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/spa/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/spa/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/spa/ls-other-outcome',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    massage_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/massage',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Massage system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/massage/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/massage/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/massage/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.service_list'),
            to: '/massage/ls-product',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/massage/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/massage/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/massage/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/massage/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/massage/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/massage/rpt-supply-debt'
              }]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/massage/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "massage-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/massage/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/massage/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/massage/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.service_list'),
            to: '/massage/ls-product',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/massage/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/massage/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/massage/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/massage/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/massage/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/massage/rpt-supply-debt'
              }]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/massage/ls-order',
            icon: 'cui-cart'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/massage/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/massage/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/massage/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/massage/ls-room',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    oto_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/oto',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Oto system']
      }]
          if(user.role==Role.Admin){
            let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/oto/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/oto/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name:this.$t('menu.supply_list'),
            to: '/oto/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/oto/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: this.$t('menu.product_management'),
            route: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.service_list'),
                to: '/oto/ls-service'
              },
              {
                name: this.$t('menu.product_list'),
                to: '/oto/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/oto/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/oto/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/oto/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/oto/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/oto/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/oto/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/oto/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/oto/rpt-supply-debt'
              }
            ]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/oto/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "oto-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/oto/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/oto/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.supply_list'),
            to: '/oto/ls-supply',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/oto/ls-order',
            icon: 'cui-cart'
          },
          {
            _name: this.$t('menu.product_management'),
            v: '/product/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.service_list'),
                to: '/oto/ls-service'
              },
              {
                name: this.$t('menu.product_list'),
                to: '/oto/ls-product'
              },
              {
                name: this.$t('menu.product_import'),
                to: '/oto/ls-im-product'
              }
            ]
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/oto/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/oto/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/oto/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/oto/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/oto/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_import_export'),
                to: '/oto/rpt-im-ex-re'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/oto/rpt-supply-debt'
              }
            ]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/oto/ls-order',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/oto/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.service_list'),
            to: '/oto/ls-service',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/oto/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/oto/ls-other-outcome',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    },
    gym_menu(user){
      let header_children=[
      {
        _name: 'CSidebarNavItem',
        name: this.$t('menu.name'),
        to: '/gym',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Gym system']
      }]
      if(user.role==Role.Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/gym/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/gym/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/gym/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.product_list'),
            to: '/gym/ls-dung-cu',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/gym/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/gym/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/gym/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/gym/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/gym/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/gym/rpt-supply-debt'
              }]
          }
        ]
        if(user.company.cocon){
          temp.splice(1,0,
            {
              key:"ds-branch",
              _name: this.$t('menu.branch_list'),
              to: '/gym/ls-branch',
              icon: 'cil-list-rich'
            })
        }else{
          _.remove(temp, {
              name: "gym-branch"
          })
        }
        temp=header_children.concat(temp)
        return temp
      }else if(user.role==Role.Branch_Admin){
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.user_list'),
            to: '/gym/ls-user',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/gym/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/gym/ls-room',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.product_list'),
            to: '/gym/ls-dung-cu',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/gym/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/gym/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
            _name: this.$t('menu.report_list'),
            route: '/baocao/',
            icon: 'cil-list-rich',
            
            _children:[
              {
                name: this.$t('menu.user_working'),
                to: '/gym/rpt-user-working'
              },
              {
                name: this.$t('menu.report_income'),
                to: '/gym/rpt-income-per-day'
              },
              {
                name: this.$t('menu.report_activity'),
                to: '/gym/rpt-company-activity'
              },
              {
                name: this.$t('menu.report_supply_debt'),
                to: '/gym/rpt-supply-debt'
              }]
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }else{
        let temp=[
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.order_list'),
            to: '/gym/ls-order',
            icon: 'cui-cart'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.client_list'),
            to: '/gym/ls-client',
            icon: 'cil-pencil'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.unit_list'),
            to: '/gym/ls-unit',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.other_outcome_list'),
            to: '/gym/ls-other-outcome',
            icon: 'cil-list-rich'
          },
          {
          _name: 'CSidebarNavItem',
            name: this.$t('menu.room_list'),
            to: '/gym/ls-room',
            icon: 'cil-list-rich'
          }
        ]
        temp=header_children.concat(temp)
        return temp
      }
    }
  }
}
</script>
