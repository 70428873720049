<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 642 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="sidebarItems"/>
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('st_responsive/toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import SidebarItems from './SidebarItems'
import { Role } from '../helpers/role.js'
import { Business } from '../helpers/business'
export default {
  name: 'TheSidebar',
  extends: SidebarItems,
  computed: {
    show () {
      debugger
      return this.$store.state.st_responsive.sidebarShow
    },
    minimize () {
      debugger
      return this.$store.state.st_responsive.sidebarMinimize
    }
  }
}
</script>

